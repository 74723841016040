<template>
  <v-simple-table>
    <template>
      <thead class="blue-grey lighten-5">
        <tr class="text-caption grey--text ">
          <th class="text-left font-weight-regular">
            Название
          </th>
          <th class="text-left font-weight-regular">
            Ключ
          </th>
          <th class="text-left font-weight-regular">
            Статус
          </th>
          <th v-if="isAdmin" class="text-left font-weight-regular">
            Специалист
          </th>
          <th class="text-left font-weight-regular">
            Последнее использование
          </th>
          <th class="text-left font-weight-regular">
            Дата создания
          </th>
          <th v-if="isAdmin" ></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in helmets" :key="item.id">
          <td>{{ item.name }}</td>
          <td>{{ item.key }}</td>
          <td>{{ item.status ? 'Активен' : 'Не активен' }}</td>
          <td v-if="isAdmin">{{ item.owner.name }}</td>
          <td class="date__width">{{ new Date(item.training_at) | moment("DD.MM.YYYY HH:mm") }}</td>
          <td class="date__width">{{ new Date(item.created_at) | moment("DD.MM.YYYY HH:mm") }}</td>
          <td v-if="isAdmin" class="two-actions__width">
            <div class="d-flex">
              <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn icon @click="edit(item.id)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                Редактировать
              </v-tooltip>
              <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn  icon @click="remove(item.id)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-package-down</v-icon>
                  </v-btn>
                </template>
                В архив
              </v-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    helmets: {
      default: []
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    remove(id) {
      this.$emit("remove",id);
    },
    edit(id) {
      this.$emit("edit",id);
    }
  },
}
</script>

<style>

</style>
<template>
  <div>
    <div class="row">
      <v-col cols="12" sm="2">
        <v-btn v-if="isAdmin" dark color="teal" @click="openCreateForm" class="mt-3 mb-6">Добавить шлем</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="2" sm="6">
        <v-select
          v-if="isAdmin"
          v-model="expert"
          :items="experts"
          itemText="name"
          itemValue="id"
          hide-details
          dense
          label="Специалист"
          color="teal"
          class="flex-grow-0 pt-2"
          item-color="teal lighten-1"/>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-tooltip top>
          <template #[`activator`]="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="getHelmets"><v-icon>mdi-reload</v-icon></v-btn>
          </template> 
          <span>Обновить</span>
        </v-tooltip>
      </v-col>
    </div>
    <helmets-list 
      ref="list" 
      :isAdmin="isAdmin" 
      :helmets="helmets" 
      @remove="confirmRemove" 
      @edit="openEditForm"/>
    <helmets-form :helmets="helmets" :open="openForm" :editId="editId" @close="closeForm"/>
    <remove-dialog :open="openRemoveDialog" @confirm="remove" @close="closeRemoveDialog">
      <template #title>Отправить шлем в архив</template>
      <template #body>Вы действительно хотите отправить шлем в архив?</template>            
    </remove-dialog>
  </div>
</template>

<script>
 
import HelmetsList from './HelmetsList'
import HelmetsForm from './HelmetsForm'
import RemoveDialog from '../main/RemoveDialog'
export default {
  components: {
    HelmetsList,
    HelmetsForm,
    RemoveDialog
  },
  data: () => ({
    loading: true,
    removeId: null,
    editId: null,
    openForm: false,
    openRemoveDialog: false,
    expert: null,
    helmets: [],
  }),
  created() {
    if (!this.isAdmin) {
      this.expert = this.$store.state.auth.user.id
    }
    this.getHelmets()
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    experts() {
      let experts =  this.$store.state.experts;
      experts.unshift({'id': null,'name': 'Все'})
      return experts;
    },
  },
  watch: {
    expert() {
      this.getHelmets();
    }
  },
  methods: {
    openEditForm(id) {
      this.editId = id;
      this.openForm = true;
    },
    openCreateForm() {
      this.editId = null;
      this.openForm = true;
    },       
    closeForm() {
      this.editId = null;
      this.openForm = false;
    },
    confirmRemove(id) {
      this.removeId = id;
      this.openRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.removeId = null;
      this.openRemoveDialog = false;
    },
    remove() {
      let id = this.removeId;
      let url = `/helmets/${id}`;
      this.openRemoveDialog = false;
       this.$api.delete(url)
        .then(() => {
          this.getHelmets();
          this.$store.dispatch('getHelmets');
          this.$store.dispatch('addMessage', 'Шлем отправлен в архив');
          //this.$store.dispatch('updateItemsList', true)
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getHelmets() {
      let url = `/helmets`;
      this.$api.get(url, {
              params: {
                  user_id: this.expert,
              }
          })
          .then(resp => {
              this.helmets =  resp.data._embedded.helmets;
          })
          .catch(error => {
              this.$store.dispatch('addRequestError', error)
          })
    }
  }
}
</script>

<style>

</style>
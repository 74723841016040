<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-card>
      <v-card-title class="mb-7">
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row align="center">
            <v-col cols="12">
              <v-text-field v-model="name" label="Название" :rules="[rules.requiredName]" color="teal"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="key" label="Ключ" :rules="[rules.requiredKey]" color="teal"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="expert"
                :items="experts"
                itemText="name"
                itemValue="id"
                label="Специалист"
                color="teal"
                item-color="teal lighten-1"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn dark color="blue-grey lighten-2" @click="close">
          Отмена
        </v-btn>
        <v-btn dark color="teal" @click="save">
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
 
export default {
  props: {
    editId: null,
    open: {
      default: false,
      type: Boolean
    },
    helmets: {
      default: []
    },
  },
  data: () => ({
    name: '',
    key: '',
    expert: null,
    valid: true,
    rules: {
      requiredName: value => !!value || 'Введите название шлема.',
      requiredKey: value => !!value || 'Введите ключ.',
    },
  }),
  watch: {
    editId() {
      let helmet = this.helmets.find(t => t.id == this.editId);
      if (helmet) {
        this.name = helmet.name;
        this.key = helmet.key;
        this.expert = helmet.owner.id;
      } else {
        this.clear();
      }
    }
  },
  computed: {
    btnText() {
      return this.editId ? 'Обновить' : 'Добавить'
    },
    dialogTitle() {
      return this.editId ? 'Обновить шлем' : 'Добавить шлем'
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    experts() {
      return this.$store.state.experts;
    }
  },
  methods: { 
    clear() {
      this.name = '';
      this.key = '';
      this.expert = null;
    },
    close() {
      this.clear();
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editId) {
          this.patch();
        } else {
          this.store();
        }
      }
    },
    store() {
      let url = '/helmets'
       this.$api.post(url,{key: this.key, name: this.name, user_id: this.expert})
        .then(() => { 
          this.close();
          this.$parent.getHelmets();
          this.$store.dispatch('getHelmets');
          this.$store.dispatch('addMessage', 'Шлем сохранен')
        })
        .catch(error => {     
          console.log(error);      
          this.$store.dispatch('addRequestError', error)
        })
    },
    patch() {
      let url = `/helmets/${this.editId}`
       this.$api.patch(url,{key: this.key, name: this.name, user_id: this.expert})
        .then(() => {
          this.close();
          this.$parent.getHelmets();
          this.$store.dispatch('getHelmets');
          this.$store.dispatch('addMessage', 'Изменения сохранены')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>
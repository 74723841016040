<template>
  <div class="helmet">
    <page-title :title="'Шлемы'"></page-title>
    <v-card class="pa-3 rounded-0 flat custom__card">
      <helmets-content></helmets-content>
    </v-card>
  </div>
</template>
<script>
import PageTitle from '../../components/main/PageTitle';
import HelmetsContent from '../../components/helmets/HelmetsContent'
export default {
  components: {
    PageTitle,
    HelmetsContent,
  },
  
}
</script>


<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-card>
      <v-card-title class="mb-7">
        <span class="headline">
          <slot name="title">Отправить в архив</slot>
        </span>        
      </v-card-title>
      <v-card-text>
        <slot name="body">Вы действительно хотите отправить в архив?</slot>        
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="blue-grey lighten-2"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          dark
          color="red darken-2"
          @click="confirm"
        >
          <slot name="btn">Архивировать</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    id: {
      default: null
    },
    open: {
      default: null
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    close() {
      this.$emit("close");
    }    
  }
}
</script>
<template>
  <v-toolbar dense class="mb-5 no-print">
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <slot></slot>
    <v-spacer></v-spacer>
    <v-breadcrumbs :items="breadcrumbs">
    </v-breadcrumbs>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    breadcrumbs() {
      let items = this.items;
      if (!items.length) {
        items = [
          {
            text: 'Главная',
            disabled: false,
            to: '/',
          },
          {
            text: this.title,
            disabled: true,
          },
        ]
      } 
      return items;
    },
  },
}
</script>
